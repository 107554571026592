.distribution {
  background: url("../images/index-line.png") repeat-x;

  .content {
    width: 1240px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
    margin: 0 auto 50px;
    padding: 50px 20px;
    border: 0;
  }
}

.top1 {
  .col-xs-4 {
    padding: 0;
  }

  p {
    margin-bottom: 0;
  }

  h3 {
    margin: 0;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0;
    margin-bottom: 15px;

    img {
      vertical-align: text-top;
    }

    a {
      font-size: 14px;
      color: #b2b2b2;
      float: right;
      letter-spacing: 2px;
    }
  }

  .about {
    width: 414px;

    p {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
      line-height: 2;
      letter-spacing: 1px;

    }

  }

  .news {
    width: 400px;
    margin: 0 43px;

    ul {
      margin: 0;
    }

    li {
      margin-bottom: 20px;
    }

    li a {
      font-size: 16px;
      color: #333333;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    li > a {
      width: 330px;
      display: inline-block;
    }

    li a:hover {
      color: #53aaea;
      text-decoration: none;
    }

    li span {
      color: #808080;
      font-size: 12px;
      float: right;
    }

    li.hot {
      h4 {
        margin: 0 0 10px 0;
      }

      h4 a {
        display: inline-block;
        width: 390px;
      }

      a {
        font-size: 18px;
      }

      p {
        color: #666666;
        font-size: 14px;
        line-height: 2;
      }

      div {
        margin: 2px 0 20px;
        font-size: 12px;
        color: #808080;
      }

      .detail {
        padding: 2px 10px;
        color: #4c4c4c;
        font-size: 12px;
        border: 1px solid #d9d9d9;
        float: right;
      }
      .detail:hover{
        color: white;
        background: #49a0e7;
      }
    }
  }

  .duty {
    width: 300px;

    p {
      margin-top: 20px;
      font-size: 14px;
      line-height: 2;
      letter-spacing: 1px;
    }
  }
}

.industry {
  position: relative;
  margin-top: 40px;

  .bg {
    font-size: 0;
  }

  .bg > div {
    display: inline-block;
    vertical-align: text-bottom;
  }

  .bg1 {
    width: 271px;
    background: #49a0e7;
    height: 227px;
    color: white;
    padding-left: 40px;

    h3 {
      margin: 0;
      font-size: 22px;
      margin-top: 40px;
    }

    hr {
      border-bottom: 2px solid white;
      width: 60px;
      margin: 15px 0;
    }

    div {
      font-size: 16px;
      margin-bottom: 20px;
    }

    a {
      color: white;
      font-size: 13px;
      border: 1px solid white;
      padding: 4px 10px;
    }
  }

  .bg2 {
    width: 260px;
    background: #49a0e7;
    height: 100px;
  }

  .bg3 {
    width: 649px;
    background: #1a72ba;
    height: 100px;
  }

  .bg4 {
    width: 20px;
    background: #1a72ba;
    height: 217px;

  }

  .industry-detail {
    position: absolute;
    top: 10px;
    right: 20px;
    border: 1px solid #d9d9d9;
    background: white;

    ul {
      margin: 0;
    }

    li {
      float: left;
      text-align: center;
      padding: 30px 25px 10px;
    }

    li:not(.last) img {
      transition: all 0.4s;
    }

    li:not(.last) img:hover {
      transform: scale(1.1);
    }

    p {
      margin-bottom: 0;
      margin-top: 10px;
    }

    a {
      color: #4d4d4d;
      font-size: 14px;
      text-decoration: none;
    }

    li.last {
      border-left: 1px solid #d9d9d9;
      position: relative;
    }

    .ptdw {
      position: absolute;
      top: 50px;
      line-height: 40px;
      text-align: left;
      left: 50%;
      margin-left: -82px;
      a {
        color: white;
        font-size: 16px;
      }

      a.odd {
        margin-left: 16px;
      }
    }
  }
}

.others {
  margin-top: 50px;

  h3 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .video {
    float: left;
    margin-right: 40px;
  }

  .culture {
    float: left;
  }

  .carousel-control.right, .carousel-control.left {
    background-image: none;
  }

  .carousel-control {
    width: auto;
    img {
      margin-top: 89px;
    }
  }
}

.hr-logo {
  text-align: center;
  margin: 75px auto;
}

.middle-logo {
  animation: spin 4s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.carousel-indicators li {
  background-color: transparent \9;
}

.banner .carousel-indicators li {
  width: 22px;
  height: 5px;
  background: white;
  margin: 1px 3px;
}

.banner .carousel-indicators li.active {
  background: #71b0ff;
  margin: 1px 3px;
  border: 1px solid #71b0ff;
}

.modal-content {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  width: 1100px;
}

@media (min-width: 992px) {
  .modal-lg {
    width: 1100px;
  }

}
